<template>
  <v-container v-if="client" fluid>
    <RouterView :client="client"/>
  </v-container>
</template>

<script>
import { show } from '@/api/endpoints/client.js';
import Client from '@/application/models/client.js';

export default {
  name: 'ClientLayoutWrapper',
  data() {
    return {
      client: null,
    };
  },
  watch: {
    '$route.params.clientId': {
      immediate: true,
      handler() {
        this.getClient();
      },
    },
  },
  methods: {
    async getClient() {
      const response = await show(this.$route.params.clientId);
      this.client = new Client().mapResponse(response.data.data);
    },
  },
};
</script>
